<template>
  <div class="card">
    <h3 class="card-title">Ver notificacion</h3>
    <template v-if="notificacion">
      <div class="cols2">
        <pd-input
            class="my-0"
            readonly
            classInput="form-control readonly text-bold text-success"
            v-model="notificacion.titulo"
            label="Título"
            id="titulo"
            placeholder="Sin datos"
        ></pd-input>
      </div>
        <pd-input
            class="my-0"
            readonly
            classInput="form-control readonly text-bold text-success"
            v-model="usuariosNotificados"
            label="Usuarios notificados"
            id="usuarios_notificados"
            placeholder="Sin datos"
        ></pd-input>
      <button class="btn btn-info" @click="openFirmados = true">Ver usuarios que firmaron</button>

      <h4>Cartelera</h4>
      <hr/>
      <div class="notificaciones">
        <div class="card" :class="classNotificacion">
          <h4 class="card-title">{{ notificacion.titulo }}</h4>
        </div>
      </div>
      <h4 class="mt-2">Notificacion completo</h4>
      <hr/>
      <div>
        <h2 class="card-title text-center mt-2 mb-2">{{ notificacion.titulo }}</h2>
        <div class="d-flex" v-if="notificacion.archivo_url">
          <embed
              :src="notificacion.archivo_url"
              style="width: 100%; height: 29.7cm"
              frameborder="0"
          />
        </div>
        <div style="padding: 0 5px" v-html="notificacion.contenido" v-else></div>
      </div>
      <div class="d-flex space-between mt-1">
        <button class="btn btn-warning" @click="editNotificacion">
          Editar notificacion
        </button>
        <button class="btn btn-error" @click="eliminarNotificacion">
          Eliminar notificacion
        </button>
      </div>
      <Modal v-if="openFirmados" @close="openFirmados = false">
        <div slot="modal-body" style="min-width: 80vw">
          <h3 class="card-title">Usuarios notificados</h3>
          <table-list
              :showFilter="true"
              :data="notificacion.notificacion_usuario"
              :header="headerUsuariosFirmados"
              :body="bodyUsuariosFirmados"
          ></table-list>
        </div>
      </Modal>
    </template>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {NotificacionesServices} from "../../notificaciones/services/NotificacionesServices";
import {UsuarioFirmadoService} from "../../procedimientos/services/ProcedimientosServices"
import Modal from "../../../components/elements/Modal.vue";
import TableList from "@/components/elements/TableList";

export default {
  name: "VerNotificacion",
  data() {
    return {
      loading: false,
      notificacion: null,
      openFirmados: false,
      headerUsuariosFirmados: UsuarioFirmadoService.headers(),
      bodyUsuariosFirmados: UsuarioFirmadoService.body(),
    };
  },
  components: {Modal, TableList},
  computed: {
    classNotificacion() {
      let clase = "";
      if (this.notificacion.tipo) {
        clase = `card-${this.notificacion.tipo}`;
      }
      return clase;
    },
    usuariosNotificados() {
      const totalUsuarios = this.notificacion.notificacion_usuario.length
      const notificados = this.notificacion.notificacion_usuario.filter((usuario) => usuario.firmado === 1).length
      return `${notificados} de ${totalUsuarios}`
    }
  },
  mounted() {
    const notificacionId = this.$route.params.id;
    this.loading = true;
    NotificacionesServices.api
        .find(notificacionId)
        .then((response) => {
          this.notificacion = response.notificacion;
        })
        .catch((error) => {
          Swal.fire("Error", `${error}`, "error");
        })
        .finally(() => (this.loading = false));
  },
  methods: {
    editNotificacion() {
      this.$router.push({
        name: "EditarNotificacion",
        params: {id: this.notificacion.id},
      });
    },
    eliminarNotificacion() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.notificacion.titulo}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          NotificacionesServices.api.delete(this.notificacion).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({name: "ListarNotificacionesAdmin"});
          });
        }
      });
    },
  },
};
</script>
